<template>
  <v-container id="basket-review">

    <CheckoutTopBar
      @go-back-action="goToMainFeed"
      @show-nav-drawer="showNavDrawer = true"
    />
    
    <div class="my-page-content">
      <v-row class="mt-0">
        <v-col cols="12" class="pb-0">
          <div class="title-4">Your order</div>
        </v-col>
        <v-col cols="12">
          <div class="body-3">Want to do a final check?</div>
        </v-col>
      </v-row>

      <div v-if="isCartEmpty">
        <v-row class="emtpy-basket-container">
          <v-col cols="10" offset="1">
            <div class="title-5">Oops!</div>
          </v-col>
        </v-row>
        <v-row class="text-center mt-0">
          <v-col cols="10" class="pt-2" offset="1">
            <div class="title-9">
              You have emptied your basket.
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-divider class="my-divider mt-4"></v-divider>

        <div
          v-for="(value, prop, index) in basketData"
          :key="'basketItem-'+index"
          class="items-list"
        >
          <v-row class="mt-5">
            <v-col cols="12" class="d-flex align-center">
              <v-avatar size="40">
                <img
                  alt="user"
                  :src="publicPath + value[0].chefAvatar"
                >
              </v-avatar>
              <div class="ml-2">
                <div class="title-8">{{value[0].chefName}}</div>
                <!-- Reviews to be added to another version -->
                <!-- <div class="chef-review-container">
                  <div>
                    <v-icon
                      size="12"
                      class="mr-1"
                    >
                      {{star_icon}}
                    </v-icon>
                  </div>
                  <div class="small-text">{{value[0].chef_review}}</div>
                </div> -->
              </div>
            </v-col>
          </v-row>

          <OrderItemTemplate
            v-for="(dishItem, dishIndex) in value"
            :key="'dish-'+dishIndex"
            :orderItem="dishItem"
            @decrease-quantity="decreaseQuantity"
            @increase-quantity="increaseQuantity"
            @open-dish-config="openDishConfiguration"
          />
        </div>

        <v-divider class="my-divider my-4"></v-divider>

        <v-row @click="showAddCommentOverlay = true">
          <v-col cols="2" sm="1">
            <v-icon size="32">{{comment_icon}}</v-icon>
          </v-col>
          <v-col cols="9">
            <div v-if="isOrderCommentEmpty">
              <div class="title-12">Add comment</div>
              <div class="small-text">
                Any allergies, special requests, dietary limitations etc?
              </div>
            </div>
            <div v-else>
              <div class="title-12">Edit comment</div>
              <div class="body-3 mt-3">{{orderComment}}</div>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-container>
        <div class="main-btn-container">
          <!-- <div v-if="showAddressLimitationMessage" class="address-limitation">
            {{addressLimitation.message}}
          </div> -->
          <v-btn
            depressed
            class="order-now-btn"
            :disabled="isCartEmpty"
            @click="saveOrder"
          >
            <div class="d-flex justify-center align-baseline">
              <span>Order now</span>
              <span class="ml-1 small-text">
                {{getTotalCartItems}}
              </span>
            </div>
            <v-spacer></v-spacer>
            <span>{{orderTotal.toFixed(2)}} &euro;</span>
          </v-btn>
        </div>
      </v-container>
    </div>

    <AddCommentOverlay
      :visible="showAddCommentOverlay"
      @close="showAddCommentOverlay = false"
    />

    <AddToCartOverlay
      :visible="showAddToCartOverlay"
      :update="true"
      @close="showAddToCartOverlay = false"
    />

    <EditDishConfOverlay
      :visible="showEditDishConfOverlay"
      @close="showEditDishConfOverlay = false"
      @update-dish-config="showEditDishConfOverlay = false"
      @open-dish-details="openDishDetails"
    />

    <SignInModal
      :visible="showSignInModal"
      @close="showSignInModal = false"
    />

    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
      @open-sign-in-modal="openSignInModal"
    />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import CheckoutTopBar from '@/components/Navigation/CheckoutTopBar';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import AddCommentOverlay from '@/components/BasketReview/AddCommentOverlay';
  import OrderItemTemplate from '@/components/BasketReview/OrderItemTemplate';
  import AddToCartOverlay from '@/components/MainFeed/AddToCartOverlay';
  import EditDishConfOverlay from '@/components/BasketReview/EditDishConfOverlay';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'BasketReview',
    components: {
      CheckoutTopBar,
      NavigationDrawer,
      AddCommentOverlay,
      OrderItemTemplate,
      AddToCartOverlay,
      EditDishConfOverlay,
      SignInModal
    },
    computed: {
      ...mapGetters([
        "cart",
        "orderTotal",
        "orderComment",
        "isUserLoggedIn",
        "addressLimitation",
        "orderReceiveMethod"
      ]),
      isOrderCommentEmpty() {
        return this.orderComment === '';
      },
      isCartEmpty() {
        return this.cart.length === 0;
      },
      getTotal() {
        let total = 0;
        for (let k = 0; k < this.cart.length; k++) {
          let item = this.cart[k];
          total += (item.quantity * item.totalItemPrice);
        }
        total = Math.round(total * 100) / 100;
        return total;
      },
      getTotalCartItems() {
        let result = '';
        let totalQuantity = 0;
        this.cart.forEach((el) => {
          totalQuantity += el.quantity;
        });
        if (totalQuantity === 1) {
          result = totalQuantity + ' item';
        } else if (totalQuantity > 1) {
          result = totalQuantity + ' items';
        }
        return result;
      },
      basketData() {
        let result = [];

        if (this.cart.length != 0) {
          result = this.cart.reduce((group, prop) => {
            let newkey = prop['chefName'];
            if(!group[newkey]) {
              group[newkey] = [];
            }
            group[newkey].push(prop);
            return group;
          }, {});
        }
        return result;
      },
      // isOrderTotalValid() {
      //   let result = true;
      //   let totalLimit = null;
      //   if (this.addressLimitation != null) {
      //     totalLimit = this.addressLimitation.totalLimit;
      //   }

      //   if (totalLimit == null || this.orderTotal < totalLimit) {
      //     result = false;
      //   }
      //   return result;
      // },
      // showAddressLimitationMessage() {
      //   return this.orderReceiveMethod == 'delivery' && this.addressLimitation != null && !this.isOrderTotalValid;
      // }
    },
    data: () => ({
      publicPath: process.env.VUE_APP_BASE_URL,
      showSignInModal: false,
      showNavDrawer: false,
      showAddCommentOverlay: false,
      showAddToCartOverlay: false,
      showEditDishConfOverlay: false,
      comment_icon: "$vuetify.icons.comment_icon",
      minus_btn: "$vuetify.icons.minus_btn",
      plus_btn: "$vuetify.icons.plus_btn",
      star_icon: "$vuetify.icons.star_icon",
      trash_icon: "$vuetify.icons.trash_icon"
    }),
    methods: {
      ...mapActions([
        "increaseDishQuantity",
        "decreaseDishQuantity",
        "setOrderTotal",
        "setDishDetails",
        "resetDishDetails"
      ]),
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      openSignInModal() {
        this.showNavDrawer = false;
        this.showSignInModal = true;
      },
      decreaseQuantity(orderItemId) {
        this.decreaseDishQuantity(orderItemId).then(() => {
          let total = this.getTotal;
          this.setOrderTotal(total);
        });
      },
      increaseQuantity(orderItemId) {
        this.increaseDishQuantity(orderItemId).then(() => {
          let total = this.getTotal;
          this.setOrderTotal(total);
        });
      },
      saveOrder() {
        let basketItems = this.cart.map((el) => {
          let itemCategory = el.tags.length != 0 ? el.tags[0].name.toLowerCase() : '';
          return {
            'item_name': el.title,
            'price': el.price,
            'quantity': el.quantity,
            'item_category': itemCategory
          }
        });

        this.$gtag.event('begin_checkout', {
          'currency': 'EUR',
          'value': this.orderTotal,
          'items': basketItems
        });

        fbq('track', 'InitiateCheckout', {
          content_type: 'product',
          value: this.orderTotal,
          currency: 'EUR'
        });

        if(this.isUserLoggedIn) {
          this.$router.push({name: 'checkout'});
        } else {
          this.showSignInModal = true;
        }
      },
      openDishDetails() {
        this.showEditDishConfOverlay = false;
        this.showAddToCartOverlay = true;
      },
      openDishConfiguration(dish) {
        this.resetDishDetails().then(() => {
          this.setDishDetails(dish).then(() => {
            this.showEditDishConfOverlay = true;
          });
        });
      }
    }
  };
</script>

<style type="text/css">
  #basket-review {
    padding: 20px 20px 150px;
  }

  .hide-elements {
    display: none;
  }

  .emtpy-basket-container {
    margin-top: 100px;
    text-align: center;
  }
</style>
