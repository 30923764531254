<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet"
    inset
  >
    <v-sheet
      class="text-center"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content">
        <v-row class="text-left mt-5">
          <v-col cols="12" class="pb-0">
            <div class="title-5">
              Add comment
            </div>
          </v-col>
          <v-col cols="12" class="pt-1">
            <div class="body-3">
              Note that your comment will also be shared with our chefs and delivery partners who prepare or deliver your order;)
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              solo
              flat
              no-resize
              rows="8"
              hide-details
              label="Add comment"
              class="my-textarea"
              v-model="order_comment"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" class="pt-0">
            <v-btn
              block
              depressed
              class="title-12 confirm-btn"
              @click="$emit('close')"
            >
              Done
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  export default {
    name: 'AddCommentOverlay',
    props: ["visible"],
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      order_comment: {
        get() {
          return this.$store.state.orders.orderComment;
        },
        set(value) {
          this.$store.commit('setOrderComment', value);
        }
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn"
    })
  };
</script>
