<template>
  <div class="d-flex mt-3 py-3 align-center">
    <div class="mr-4">
      <v-card
        flat
        class="checkout-quantity-btn big-quantity-btn"
      >
        <div>
          <v-btn
            v-if="orderItem.quantity == 1"
            icon
            @click="$emit('decrease-quantity', orderItem.orderItemId)"
          >
            <v-icon size="18">{{trash_icon}}</v-icon>
          </v-btn>

          <v-btn
            v-else
            icon
            @click="$emit('decrease-quantity', orderItem.orderItemId)"
          >
            <v-icon size="18">{{minus_btn}}</v-icon>
          </v-btn>
        </div>
        <div class="title-5 py-1 px-2">
          {{orderItem.quantity}}
        </div>
        <div>
          <v-btn
            icon
            @click="$emit('increase-quantity', orderItem.orderItemId)"
          >
            <v-icon size="18">{{plus_btn}}</v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
    <div class="details-container">
      <v-row>
        <v-col cols="9" class="px-1 pb-1 pl-sm-3 pr-sm-1">
          <div
            class="title-10 my-link"
            @click="$emit('open-dish-config', orderItem)"
          >
            {{orderItem.title}}
          </div>
        </v-col>
        <v-col cols="3" class="pb-1 text-right">
          <div class="title-13">{{orderItem.totalItemPrice.toFixed(2)}} &euro;</div>
        </v-col>
      </v-row>
      <v-row v-if="hasModifiers || hasIngredients">
        <v-col cols="12" class="py-0">
          <v-list dense class="pa-0" id="ingredients-list">
            <v-list-item
              v-for="(item, index) in item_details"
              :key="'ingr-'+index"
              class="pa-0"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <div class="body-3">- {{item}}</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'OrderItemTemplate',
    props: ["orderItem"],
    computed: {
      ...mapGetters([
        "cart"
      ]),
      item_details() {
        let ingredient = '';
        let result = [];

        if (this.hasModifiers) {
          let selectedModifiers = this.orderItem.selectedModifiers.map((el) => {
            return this.orderItem.modifiers[el].content.en.title;
          });
          result = [...selectedModifiers];
        }

        if (this.hasIngredients) {
          ingredient = this.orderItem.ingredients[this.orderItem.selectedIngredient].content.en.title;
          result.push(ingredient);
        }

        return result;
      },
      hasModifiers() {
        return this.orderItem.modifiers.length != 0;
      },
      hasIngredients() {
        return this.orderItem.ingredients.length != 0;
      }
    },
    data: () => ({
      minus_btn: "$vuetify.icons.minus_btn",
      plus_btn: "$vuetify.icons.plus_btn",
      trash_icon: "$vuetify.icons.trash_icon"
    }),
    methods: {
      ...mapActions([]),
    }
  };
</script>

<style type="text/css">
  .hide-elements {
    display: none;
  }

  #ingredients-list .v-list-item {
    min-height: 0;
  }

  #my-app .big-quantity-btn {
    max-width: unset;
    max-height: unset;
  }

  .details-container {
    flex: 1;
  }

  @media (max-width: 600px) {
    #my-app .big-quantity-btn {
      padding: 4px 8px;
    }
  }
</style>