<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet"
  >
    <v-sheet
      class="text-center"
      id="edit-dish-conf-overlay-sheet"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content">
        <v-row class="mt-5">
          <v-col cols="12" class="pb-1">
            <div class="title-5">
              {{dishDetails.title}}
            </div>
          </v-col>
          <v-col cols="10" class="pt-1" offset="1">
            <div class="body-1">
              {{dishDetails.description}}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" offset="3">
            <div class="checkout-quantity-btn">
              <div>
                <v-btn
                  icon
                  @click="decreaseQuantity"
                >
                  <v-icon size="18">{{minus_btn}}</v-icon>
                </v-btn>
              </div>
              <div class="title-5 py-1 px-2">
                {{dishDetails.quantity}}
              </div>
              <div>
                <v-btn
                  icon
                  @click="increaseQuantity"
                >
                  <v-icon size="18">{{plus_btn}}</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              text
              color="#001254"
              class="small-text"
              @click="$emit('open-dish-details')"
            >
              Customize Item
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12" class="pt-0">
            <v-btn
              block
              depressed
              class="title-12 confirm-btn"
              @click="$emit('update-dish-config')"
            >
              Update
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'EditDishConfOverlay',
    props: ["visible"],
    computed: {
      ...mapGetters(["dishDetails", "cart"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      order_comment: {
        get() {
          return this.$store.state.orders.orderComment;
        },
        set(value) {
          this.$store.commit('setOrderComment', value);
        }
      },
      getTotal() {
        let total = 0;
        for (let k = 0; k < this.cart.length; k++) {
          let item = this.cart[k];
          total += (item.quantity * item.totalItemPrice);
        }
        total = Math.round(total * 100) / 100;
        return total;
      },
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      minus_btn: "$vuetify.icons.minus_btn",
      plus_btn: "$vuetify.icons.plus_btn",
    }),
    methods: {
      ...mapActions([
        "decreaseDishQuantity",
        "increaseDishQuantity",
        "resetDishDetails",
        "setOrderTotal"
      ]),
      decreaseQuantity() {
        let orderItemId = this.dishDetails.orderItemId;

        this.decreaseDishQuantity(orderItemId).then(() => {
          let matchedDish = this.cart.find(el => el.orderItemId == orderItemId);
          if (matchedDish == undefined) {
            this.resetDishDetails().then(() => {
              this.$emit('close');
            });
          }

          let total = this.getTotal;
          this.setOrderTotal(total);
        });
      },
      increaseQuantity() {
        let orderItemId = this.dishDetails.orderItemId;
        this.increaseDishQuantity(orderItemId).then(() => {
          let total = this.getTotal;
          this.setOrderTotal(total);
        });
      },
    }
  };
</script>

<style type="text/css">
  #edit-dish-conf-overlay-sheet .checkout-quantity-btn {
    max-width: unset;
    max-height: unset;
  }
</style>